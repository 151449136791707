import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import home from '../img/home.jpg';
import thesubrahmanyanprocess from '../img/thesubrahmanyanprocess.jpg';
import thesubrahmanyanprocess1 from '../img/thesubrahmanyanprocess1.png';
import phillipines from '../img/phillipines.png';
import './Philippines.css';

export default function Philippines() {
    const history = useHistory();
    const onClickBack = (event) => {
        history.push("/");
    }
    const onClickNext = (event) => {
        history.push("/pprc");
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Dr. V. Subrahmanyan | Philippines</title>
            </Helmet>
            <div className="headerimg">
                <img src={home} style={{ height: '155px', width: '100%' }} />
            </div>
            <div className="container" >
                <br></br>
                <div className="earlylife" >The Phillipines</div>
                <br></br>
                <div className="earlylifepara" >Subrahmanyan was seconded as an Expert Consultant of the FAO to help establish a food technology laboratory in the National Institute for Science and Technology at Manila. In the two years of his assignment, he not only set up the laboratory, but also guided some practically important research projects on the preservation and processing of the coconut and 'copra' (desiccated coconut) products of great economic value to that country. By the use of simple chemical sprays, he was able to work out procedures for the preservation of coconut and to produce infection-free copra from it by the ordinary drying procedures. The local commercial organizations hailed this as a big break-through as it helped to save millions of dollars-worth of the main agricultural produce of the islands from going to waste and which could be exported or processed. </div>
                <div>
                    {/* <img className="thesubrahmanyanprocess" src={thesubrahmanyanprocess} /> */}
                    <img className="thesubrahmanyanprocess" src={thesubrahmanyanprocess1} />
                    <div className="earlylifepara" >Subrahmanyan was an FAO Food Technologist in Philippines in the years, 1964-1966. He introduced a chemical dip pre-treatment for the preservation of coconuts during drying. "This has since become known as the Subrahmanyan process (1966) The pre-treatment involved washing the kernel in a dilute solution of alkali (sodium carbonate or acid (Sulphuric or Hydrochloric acid). The equipment used was very simple-requiring wooden vessels and bamboo or rattan baskets for holding the kernels during the dipping operation. This process however meant an extra cost to the producer. The pre-treatment of the kernel as cut pieces or in the half shell is followed by the traditional methods such as sun or kiln drying. This process which is only a surface action with penetration of less than a millimetre was found to be very effective in preserving the coconut from attack by microorganism, its usefulness was not only in protecting the kernel prior to drying but also in keeping qualities after drying. The initial trials with alkaline treatment proved to be encouraging with minimal loss of oil and the quality of the expelled oil was excellent (FFA under 0.1%) edible quality. The process has not been put to commercial use due to problems when large trials were conducted in the U.S.A. The alkaline treatment resulted in a soapy surface causing slip in the expellers. The acid treatment was supposed to cause corrosion problems with milling equipment".</div>
                    <br></br>
                </div>
                <br></br>
                <div style={{ display: 'inline' }} >
                    <img src={phillipines} style={{ height:'350px', width:'100%' }} />
                    <div style={{ fontSize: '12px', textAlign: 'center' }}>Subrahmanyan and Saraswathi in the Phillipines.</div>
                    <br></br>
                </div>
                <div className="earlylifepara" >He received  awards for this research from  the Moro Planters Association and from the National Institute of Science and Technology, Philippines.</div>
                <br></br>
                <div className="earlylife" style={{ fontSize: 'large' }} >References</div>
                <br></br>
                <div className='referencepara'>(1) Coconut Harvesting and Copra Production  <a href="https://open.unido.org/api/documents/4788145/download/COCONUT%20HARVESTING%20AND%20COPRA%20PRODUCTION.%20COCONUT%20PROCESSING%20TECHNOLOGY%20INFORMATION%20DOCUMENTS.%20PART%201%20(09979.en)" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">"COCONUT PROCESSING TECHNOLOGY”</font></a> INFORMATIONDOCUMENTSUNIDOdocument (2) Subrahmanyan V. (1965) Control of infection and production of quality products from Coconut -without machinery or application of heat. Philippine Journal of Nutrition 218-234</div>
                <br></br><br></br>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="backbutton" ><Button size="small" onClick={onClickBack} >Back</Button></div>
                    <div className="backbutton" ><Button size="small" onClick={onClickNext} >Next</Button></div>
                </div>
            </div>
        </>
    )
}