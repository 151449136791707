import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import home from '../../../img/home.jpg';
import mysoreflour4 from'../../../img/mysoreflour4.png';
import './MysoreFlour.css'; 

export default function MysoreFlourContinues() {
    const history = useHistory();
    const onClickNext = (event) => {
        // history.push("/rice");
       // uncomment the above line once rice page is ready and delete the following
        history.push("/philippines");
    }
    const onClickBack = (event) => {
        history.push("/mysore-flour-continued");
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
             <Helmet>
                <title>Dr. V. Subrahmanyan | MysoreFlour Continues</title>
            </Helmet>
            <div className="headerimg">
                <img src={home} style={{ height: '155px', width: '100%' }} />
            </div>
            <div className="container" >
                <br></br>
                <div className="earlylife" >Mysore Flour and Indian Multipurpose Food(continued...)</div>
                <br></br>
                <div className='earlylifepara'>In the first decade of the twentieth century, there was a continuation of the earlier work based on both use of Indian legumes The CFTRI then continued to address (1) Inadequacy of food intake among the poorer segment leading to calorie deficiency (2) The extra nutritional need of infants and preschool children (3) Deficiency of specific micronutrients such as Vitamin A and iron Although cereal-legume blends would provide the requirement of both proteins and calorie to preschool children, they pose a number of hurdles, in large-scale programs, which include raw material ingredients that have to be cooked to be acceptable, the distribution centers should be equipped with hygienic kitchens. The processing and preparation times are high. Keeping these factors in view CFTRI has developed ‘Energy Food’ which is ready to eat and does not need cooking at the point of distribution. </div>
                <br></br>
                <div className='earlylifepara'>In the original energy formulation, wheat, Bengal gram dhal, Jaggery and edible grade groundnut flour was used as ingredients along with vitamin and mineral premixes. The use of edible grade groundnut flour was discontinued due to possible Aflatoxin contamination, cost and non-availability of expeller pressed groundnut cake meeting the required specifications. (The possibility of aflatoxin contamination and reduction have been discussed). This was replaced with defatted soy flour. The raw materials are precleaned, roasted, cooled, ground to a powder and mixed with sugar. The mixture is fortified with vitamins and minerals (38). The Energy food could provide 15g of protein and 360 kcal of energy per 100 g serving. Energy food was designed to be   ready-to-eat formulation and is distributed to the beneficiaries in powder form (Similar to the Indian MPF). The beneficiaries, namely mothers and children, are advised to mix the food in water and consume the same in the form of gruel or laddu. The ‘Energy Food’ was an almost ready to eat food and does not need extensive cooking at the point of distribution and consumption and was primarily designed of the purpose of supplementation or nutritional needs of the undernourished or malnourished children, is also popularized as a consumer item. It may also be consumed as snack. </div>
                <br></br>
                <div className='sago' >
                    <img className='emsworth' src={mysoreflour4} />
                    <div style={{ fontSize: '12px', textAlign: 'center' }}>Ambassador Elmsworth Bunker from the United Stated in 1960. Elmsworth Bunker was associated with the US Aid program (P.L. 480) which funded some of C.F.T.R. I. work.</div>
                    <br></br>
                </div>
                <div className='earlylifepara'>The technology was released to many firms and entrepreneurs throughout India. In Karnataka, the technology was released to M/S Karnataka State Agro Corn Products under the Department of Agriculture, a Government of Karnataka Undertaking Registered on 06-04-1973, under the company’s Act 1956. The Registered Office of the company was at Hebbal, Bellary Road, Bangalore and was engaged in production of Maize and Feed products at the Head Office and Energy Foods at its units located at Mysore, Belgaum, Chitradurga, Raichur and Doddaballapur (37). The Company was functioning as an independent company since 1989. Twelve tonnes of energy food were made at each unit (37). Production activities of the Company came to a stop w.e.f 01-04-2007. The Govt. vide its order Kru Tho E/04/ Kru E Mum 2010 Bengaluru dated 22-02-2012.ordered closure of the company. Accordingly, all employees except those on deputation have been relieved under VRS scheme w.e.f 31st March. </div>
                <br></br>
                <div className='earlylifepara'>Natarajan <font style={{fontStyle:'Italic'}}>et al</font> 1979 (35) wrote a review on the status of supplementary foods in India with special reference to “Energy Food” They emphasized the use of locally available material. The use of soya meal was not discussed nor was the problem of aflatoxin. There is mention of soya being distributed for use supplementary feeding by CARE. “Food ingredients like Bulgur wheat, soya and milk powder used in the nutrition programs are procured from other countries through CARE, UN and other agencies. Thus, the nutrition intervention programs in India have depended heavily on foreign assistance whose withdrawal would necessitate national efforts on ameliorating dietary deficiencies in vulnerable groups of population (35). </div>
                <br></br>
                <div className='earlylifepara'>“Under an agreement with FCI, during my tenure, a total of 53,468 metric tons Balahar “child’s food” was produced at South India Flour Mills limited in Royapuram Beach Road,” write the then head of CARE at Madras (Chennai), Tim Lavelle.” The Balahar formulation was devised by the National Institute of Nutrition and Regional Research Laboratory in Hyderabad (Dr. C. Gopalan) and the Central Food Research Institute in Mysore. CARE supplied the CSM (Corn, deoiled Soymeal, skim Milk powder) and FCI procured the groundnuts, pulses, vitamins and minerals” he continued (41). 16,425 tonnes of Balahar, were produced by the Food Corporation of India in 1968 (32) while 39,154 tonnes of Balahar was produced for CARE and 2868 for UNICEF and 32632 tonnes given to the Government of India in 1976 (34).  Production of Balahar was discontinued in 1980 (36).  Balahar uses wheat, Corn and soyabean along with Bengal gram unlike the Indian Multipurpose food that used wheat, Bengal gram and groundnut cake.  Both the Indian Multipurpose food or the Balahar are usually converted into  a dish before feeding to children.</div>
                <br></br>
                <div className='earlylifepara'>In Gujarat, <a href="https://timesofindia.indiatimes.com/city/ahmedabad/amul-rations-to-help-govt-fight-malnourishment/articleshow/64607946.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst&pcode=461" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">Bhalbhog</font></a> is being manufactured by Amul Dairy and distributed throughout the state as take home rations and consists of wheat, Bengal gram, soybean flour, sugar, oil and rice and that the state government would purchase   from Amul around 6,00 tones food material worth around Rs 600 crore. Addition of micronutrients in Bhalbhog is particularly mentioned in another report (40)</div>
                <br></br>
                <div className='earlylifepara'>“It is evident from the foregoing  account (This is from a review on processed foods for better nutrition) that besides cereals and pulses which form important sources of protein in the diets of the low income groups in tropical countries, oilseeds and oilseed  meals represent an abundant and a most important source of proteins which have not been fully utilized for supplementing human diet”  and “There is no doubt that increased production and consumption of processed protein food fortified adequately with synthetic vitamins , at a cost lower than natural protective food, will play a very  important role in improving the diet and nutritional status of the majority in tropical countries.” (13)</div>
                <br></br>
                <div className="earlylife" style={{ fontSize: 'large' }} >References</div>
                <br></br>
                <div className='referencepara'> (1) Subrahmanyan. V (1952) Industrial crops as food during times of emergency. Bulletin of Central Food Technological Research Institute.2:7-9... (2) Mysore Flour: Applications of science to relief of distress in India. (1953) Bulletin of the C.F.T.R.I., Mysore.  (3) Subrahmanyan, V.. Murthy, H. B. N,  Swaminathan, M. (1954) Effects of Partial Replacement of Rice, Wheat or Ragi (Eleusine coracana) by Tuber Flours on the Nutritive Value of Poor Vegetarian Diets. British Journal of Nutrition 8: 1-10 (4) Murthy, H., Swaminathan, M., and Subrahmanyan, V. (1954) Effect of partial replacement of rice in a rice diet by a Tapioca flour on the metabolism of Nitrogen, calcium and phosphorus in adult human beings. British Journal of Nutrition 8:11-
                (5). Subrahmanyan, V. and Doraiswamy, T. R. and Swaminathan, M. and Sankaran, A. N. (1954) Large-scale feeding experiments with Mysore flour in distress areas of Madras state. Bulletin of Central Food Technological Research Institute, 3: 267-269. (6) C.S.I.R. Report of the second reviewing committee of the CSIR. (1954) page 26. New Delhi. (7) Karnad, R. (1957) Further field trials were made with the Indian multipurpose food developed by Subrahmanyan et al Proceedings. Symposium on Proteins, Mysore 1960 pp.415-420. Food Sci., 1957, 6, 76 (8) Subrahmanyan.V, Ramarao, G., Kuppuswamy, C., Narayana Rao, M. and Swaminathan, M. (1957) Standardization of condition for the production of Indian Multipurpose Food. Food Science, 6: 76.-80. (9)  Parpia, H.A. B., Swaminathan, M.S., and Subrahmanyan, V., (1957) Plan for the manufacture of Indian multipurpose food, Food Sci., 6,96–99, 1957 
                (10). Subrahmanyan.V., Joseph. K, Doraiswamy, T., Narayanara, M., Sankaran, A. and Swaminathan, M. (1957) The effect of supplementary multipurpose food on the growth and nutritional status of schoolchildren. British Journal of Nutrition. 11:382-388. (11) Subrahmanyan,V., Doraiswamy,T. R.., Kantha Joseph, Swaminathan, M. (1957) Treatment of nutritional oedema syndrome (Kwashiorkor) with a low cost protein food. The Indian Journal of Pediatrics. 24 :112–115. (12) Subrahmanyan.V. and Swaminathan, M. (1958) Utilization of tapioca flour and low-fat groundnut flour in meeting the food shortage. Food Science 7: 287-392. (13)  Subrahmanyan.V., Bhagvan, R.K. and Swaminathan, M. (1958) The place of processed protein foods in the treatment and prevention of protein malnutrition in children. The Indian Journal of Pediatrics  25, 216-227. 
                (14)  Subrahmanyan, V., Doraiswamy, T.R., Bhagavan, R.K., Narayana Rao M., Sankaran A.N.  and Swaminathan, M.  (1959) The Effect of Supplementary Processed Protein foods fortified with essential vitamins and minerals on the growth and nutritional status of under nourished weaned infants. Indian Journal of Pediatrics. 26: 406-413. (15) Parpia, H.A.B. and Subrahmanyan.V. (1959) Some aspects of the utilization of food research in India in Some aspects of Food Technology in India (1959) Eds H.A.B. Parpia, R.C. Bhutiani, K.L. Radhakrishnan, A.N. Sankaran, B.V. Subrayappa, C.F.T.R.I. Mysore. (16). Estimates Committee 1959-60. Eighty-Third report (second Lok Sabha) Ministry of Scientific Research and cultural affairs. Part IV. National Laboratories Lok Sabha Secretariat, March 1960. 
                (17)  Balu V., and Parpia, H.A.B. (1958). Tapioca macaroni goes to the people. Food Sci. Ext. Ser. Bull. No. 1. (18).  Hearings before a subcommittee of the committee of appropriations House of Representatives ninety First congress first session subcommittee on departments of Labor and Health, education and welfare and related agencies (1969) <a href="https://www.govinfo.gov/app/details/CHRG-91hhrg29867Op4/context" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">U.S. Government Printing Office 29-8670 Washington: 1969 Page 489-492.</font></a> (19) Borsook, H. (1962) Plain talk about nutrition. Engineering Science 27: 9-17. (20) Collection 1107 Meals for Millions of Archives. Box 42. The Library, University of California, Los Angles 
                (21) <a href="https://eparlib.nic.in/bitstream/123456789/3026/1/lsd_03_06_17-12-1963.pdf" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">LOK SABHA debates</font></a>, December 17, 1963. Sixth Session (third Lok Sabha), Lok Sabha Secretariat, New Delhi page 15 question 5055-5056. (22) Subrahmanyan.V (1963) Present status and recent developments relating to some  classes of protective foods with indications for future lines of Research and Application. Food Science, 12, 129-147. (23) Subrahmanyan, V. (1963) Introductory remarks at opening of Mysore Branch of Meals for Millions Association at Bangalore, delivered on Dec 27, 1963.Box No. 42 UCLA Library. (24)  The Windsor Star July 26, 1963 (25) Journal of Nutrition and Dietetics - Volumes 1-2 1964
                (26) The School Lunch Programme Organisation and Outcomes (1966) Rajamml P. Devadas and A. Radharukmami Publication No. 753 Ministry of Education. Government of India Press, Faridabad. (27) Rao, B.R.H., Benjamin,B.R.H.,  Jesudian, V.,G. and Dumm, M.E., (1965.) Indian multipurpose food and low-fat groundnut flour as supplements for school children. The Indian Journal of Pediatrics, 32:.1-9. (28)  Dumm M.E., Rao, B.R., Jesudian. G., and Benjamin V. (1966) Supplemented groundnut protein isolate in pre-school children. Indian Journal of Nutrition and Dietetics. 3:25-32.  (29) Dumm M.E., Rao, B.R., Jesudian. G., and Benjamin V.  (1967) Indian multipurpose food and supplemented groundnut protein isolate compared as supplements for preschool children. Indian Journal of Nutrition and Dietetics. 4:285-94.
                (30)  Annual Report of the C.F.T.R.I (1968). Page 85.(31) Journal of Nutrition and Dietetics 5: 206. 1968 (32)  <a href="http://fci.gov.in/app/webroot/upload/Finances/Annual%20Report%201968-69.pdf" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9"> FCI annual report 1968-69 page 19 </font></a>. (33)Bhattacharya,K.R. (1973) Science and Scientists in the service of imperialism. Social Scientist 2:52-59. (34) <a href=" http://fci.gov.in/app/webroot/upload/Finances/Annual%20Report%201976-77.pdf" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9"> FCI annual report 1978-79 page 29 </font></a> (35)  Natrajan ,C.P, Shankar, SV, Srihari, B.R, Rao, N.M.S and Rao,R.M.R. (1979)  Food requirements and supply to special nutrition programme in India. Indian Food Packer. 33:31–52. 
                (36) <a href="http://fci.gov.in/app/webroot/upload/Finances/Annual%20Report%201980-81_compressed%20(1).pdf" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">FCI annual report 1980-81.page 34</font></a>. (37)  CFTRI Annual Report 1983 page 30. (38) Khanam, A., Chikkegowda, R. K., & Swamylingappa, B. (2013).  <a href="https://doi.org/10.1007/s13197-011-0344-x" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">Functional and nutritional evaluation of supplementary food formulations.</font></a> Journal of Food Science and technology, 50: 309–316. (39)<a href="https://kammasworld.blogspot.com/2018/09/govindarajulu-pioneer-par-excellence.html" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9"> Govindarajulu-pioneer-par-excellence</font></a>. 
                (40)  Priyanka Kumari, Madhavika Bajoria, Kalpana Beesabathuni Sight and Life, Bangalore, India Alex Burns, Alex Hardin, Danielle Minnett, Nayantara Bhandari (2020) <a href="https://sightandlife.org/doi/10.52439/ATPJ5711" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9"> A Public-Private Partnership in Gujarat: The Amul Case Study </font></a>. (41)  <a href="https://www.google.co.in/books/edition/Care_Alumni_Memories/rCpR0RgGNioC?hl=en&gbpv=1&dq=Balahar+food+FCI&pg=PA60-IA11&printsec=frontcover" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">CARE (2012)</font></a> Tim Lavelle page 355 compiled by Chjeenu Sreenivasan Ready Worth Publications New Delhi.(42) Joanna Simonow (2021): The Rise and Demise of Multi-Purpose Food in India: Food Technology, Population Control and Nutritional Development in the Post-War Era, c. 1944–66, South Asia: Journal of South Asian Studies.</div>

                <div style={{ display: 'flex', justifyContent:'space-between' }} >
                    <div className="backbutton" ><Button size="small" onClick={onClickBack} >Back</Button></div>
                    <div className="backbutton" ><Button size="small" onClick={onClickNext} >Next</Button></div>
                </div>
            </div>
        </>
    )
}