import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import home from '../../../img/home.jpg';

export default function MysoreFlourContinued() {
    const history = useHistory();
    const onClickNext = (event) => {
        history.push("/mysore-flour-continues");
    }
    const onClickBack = (event) => {
        history.push("/mysore-flour");
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
             <Helmet>
                <title>Dr. V. Subrahmanyan | MysoreFlour Continued</title>
            </Helmet>
            <div className="headerimg">
                <img src={home} style={{ height: '155px', width: '100%' }} />
            </div>
            <div className="container" >
                <br></br>
                <div className="earlylife" >Mysore Flour and Indian Multipurpose Food(continued...)</div>
                <br></br>
                <div className='earlylifepara'>Joanna Simonow in 2021 (42) traces the history of the Indian Multipurpose food. It was supported by the Meals for Million Foundation. The president of the Indian chapter was the agriculture minister Mr.Panjabrao Deshmukh. Radha Karanth was the secretary and treasurer of the organization  (Dr. Miss R. Karnad M.Sc. Ph.D. Deputy Director (Nutrition), Ministry of Food and Agriculture (Food). She was deputy assistant director general of health services (nutrition) in 1971; Born: 13th August 1914: The Gazette of India, May 5th 1951;) “Since its inception, the meals for  Millions Association of India has rendered  valuable service by doing educational work, making health observation in collaboration with the local authorities and distributing protective foods for the benefit of vulnerable groups all over the country. The role of this effort will be appreciated when it is realized that all the executives, starting from the President , have been functioning in an honorary capacity and that Dr.Radha Karnad, the Secretary-Treasurer, has been doing all her work for the association, outside her office hours. Now her sister Smt. Suguna and her husband, have joined the ranks in keeping with the high traditions set by the late Sadsiva Rao Karnad, whose services and sacrifices for the cause of the Country”s freedom we all gratefully remember”( 23). An obituary to Sadasiva Karnad published in 1937 “His two daughters are not given any permanent footing in life. They are still studying, One being a research scholar (Radha) and the other reading in the college at Bangalore (Suguna)” (Saturday, Jan 23, 1937 Deccan Chronicle) </div>
                <br></br>
                <div className='earlylifepara'>Both research funding for the further development of blends of cereals, legumes and oil seed for use in supplementary feeding was continued at the C.F.T.R.I. under the ageis of a NIH grant under the PL 480 scheme . Feeding trials were supported similarly at the Christian Medical College, Vellore (18) <br></br> <br></br>Twenty-two girls aged 4-12 years were fed “poor vegetarian diet” and for another set of twenty-two girls, the food was supplemented with multipurpose food composed of groundnut flour and Bengal-gram flour, fortified with some vitamins and calcium phosphate. There was a significant increase in height, weight, red-cell count and hemoglobin levels of the subjects receiving the supplement whereas no children in the un-supplemented group improved. The authors thanked “the authorities of the Good Shepherd Convent, Mysore, for providing facilities for conducting the experiment” (10).</div>
                <br></br>
                <div className='earlylifepara'>Three children, aged 2-3 years, suffering from protein calorie nutrition with previous dietary intake of 500-600 calories per day and protein intake of 10-12g of protein per day and with generalized edema, diarrhea and extreme weakness were fed with a supplement of Multipurpose food (4-5 oz daily; 1 oz equals about 28.4g) was fed to them for six to eight weeks, Disappearance of edema, dermatitis and hyperpigmentation occurred during the period of treatment. The children put on weight during the period. There was increase in the level of total serum protein, serum, albumin and globulin on treatment. The authors thanked Dr. (Miss) G Gillespie and Dr. (Miss) D.E.M. Pearce for their cooperation and help during treatment and in the investigation (11)</div>
                <br></br>
                <div className='earlylifepara'>Fifty-four children varying in age from nine months to twenty months were divided into three groups. One containing a rice basal diet, another in addition received supplementation with a food consisting of malted sorghum, low fat ground nut cake, Bengal gram flour and skim milk powder, The third group received Multipurpose food (with skim milk powder). The supplements were fed in the form of sweetened porridge. Height, Weight and Blood hemoglobin were measured at the beginning and at the end of nine months. Eighty percent of infants in the experimental work improved in nutritional status whereas none in the control group did, some of whom deteriorated further. (14) </div>
                <br></br>
                <div className='earlylifepara'>At Ankola children aged from 2 to 6 years, 41 boys and 63 girls, received 1 oz multipurpose food daily in addition to their ordinary diet, and 48 boys and 40 girls served as controls. During 6 months the children given the food improved in nutritional state while Haemoglobin levels in their blood rose considerably above that of the controls.(7)</div>
                <br></br>
                <div className='earlylifepara'>At Calcutta, feeding trials were made on 43 boys and 48 girls in an orphanage. Children up to 12 years received 1/2 oz and those over 12 received 1 oz daily of Indian Multipurpose food as well as Nutro biscuits (1/2 oz daily for 6 weeks). Most of the children clearly improved in nutritional status while several of those not given the supplements deteriorated. Trials at hospitals in Bombay showed that Nutro biscuits were acceptable and contributed to weight gain in children fed them. At Government Stanley Hospital, Madras, 82 children with malnutrition were given those biscuits, I oz supplying 10.3 g protein, 105 kcal with Ca, thiamine, riboflavin, nicotinic acid and vitamins A and D. Children from 6 months onwards readily accepted the biscuits, which were easily digested and caused no adverse effect. Another product developed was Nutro biscuits which was made by the Britannia Biscuit Company and distributed to school children through the Meals for Millions foundation (18,22). Nutro biscuits could be used as a chief source of protein in the diet and which compared favorably with dried skimmed milk for treatment of protein malnutrition. (7)</div>
                <br></br>
                <div className='earlylifepara'>Rao <font style={{fontStyle:'Italic'}}>et al</font> (27) fed thirty-six boys with a supplementation of Indian MPF and thirty-five with low fat ground nut flour (to a basal diet) starting in November, 1962. The feeding was continued for five months. There was little difference in nutritional improvement between children fed MPF and those fed low fat ground nut cake. Children on both these foods gained more weight and height and showed improved indices of health. Dumm and co-workers (28,29) compared Indian MPF, a high protein diet (also developed at the C.F.T.R.I.) and a rice basal diet in a number of feeding trials. They report that the Indian MPF was seen to improve health of children more that did the basal diet but reported that the high protein formulation fared better.</div>
                <br></br>
                <div className='earlylifepara'>In 1963, Subrahmanyan received the Babcock Hart Award from the Institute of Food Technologists, USA for work on creating food for children. The Babcock-Hart Award for 1962 was for developing the Indian Multi-Purpose Food. This award was also sponsored by the Nutrition Foundation and Meals for Millions Association of USA. <a href="https://en.wikipedia.org/wiki/Babcock-Hart_Award#:~:text=The%20Babcock%2DHart%20Award%20has,the%20agricultural%20chemist%20Stephen%20M." target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">The Babcock-Hart Award</font></a> has been awarded since 1948 by the Institute of Food Technologists. It is given for significant contributions in food technology that resulted in public health through some aspects of nutrition. The award is named after Stephen M. Babcock and his colleague, Edwin Hart</div>
                <br></br>
                <div className='earlylifepara'>Dr. Deshmukh visited the U. S .at the same time as did Dr. V. Subrahmanyan who came to the US to receive the Babcock Hart award. Present at a meeting of the meals for Millions held for Dr. Deshmukh were Mrs. Lucerita del Valle Grady, wife of the then late American ambassador to India, Henry Francis Grady. Also present was Miss Florence Rose, Executive director of the Meals for Millions. Dr. Deshmukh's trip to the U.S. was sponsored by Farmers and World Affairs Inc. of Philadelphia (24)</div>
                <br></br>
                <div className='earlylifepara'>Moreover, Subrahmanyan towards the end of his tenure at the C.F.T.R.I. wrote to Ernest Chamerlin, Head of the MFM, emphasizing that the Indian Multipurpose Food was developed from resources available in India and from research work carried out in India. On July 6th 1961 Ernest Chamberlin, President of the Meals for Millions replying to a letter written by Subrahmanyan of June 16th writes “Our consolation is that we proudly publicize the fact that Indian MPF is now of proven acceptability, a sophisticated product with prestige value as a good food and not merely a relief food”. (20). Simonow (42) records the diminished role of the Indian Multipurpose Food and the increased traction for supplementary foods such as Balahar and Balamul which were also developed at the C.F.T.R.I. but contained soybean flour. Bhattacharya (33) stated that “The Central Food Technological Research Institute (C.F.T.R.I.) seven years back developed a formulation from groundnut flour (75%), Bengal gram flour (25%) fortified with vitamins and minerals, called Indian multipurpose food. It was not earth-shattering research. But it was a simple, down to earth, solid contribution made by a team with patients work over several years.” “But the CARE came in with free gifts of corn-soya-skim milk powder and the MPF programme was shelved.  The Coimbatore and other plants have remained idle since that time---” A similar statement is made in Natarajan <font style={{fontStyle:'Italic'}}>et al</font> (35)</div>
                <br></br>
                <div className='earlylifepara'></div>
                <br></br>

                <div style={{ display: 'flex', justifyContent:'space-between' }} >
                    <div className="backbutton" ><Button size="small" onClick={onClickBack} >Back</Button></div>
                    <div className="backbutton" ><Button size="small" onClick={onClickNext} >Next</Button></div>
                </div>
            </div>
        </>
    )
}