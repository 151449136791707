import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import home from '../../img/home.jpg';
import kcreddy from '../../img/kcreddy.jpg';
import convocation from '../../img/convocation.png';
import cftriopening from '../../img/cftriopening.png';
import bcguha from '../../img/bcguha.jpg';
import './Cftri.css';


export default function Cftri() {
    const history = useHistory();
    const onClickBack = (event) => {
        history.push("/cheluvajamanni");
    }
    const onClickNext = (event) => {
        history.push("/cftri-continued");
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Dr. V. Subrahmanyan | CFTRI</title>
            </Helmet>
            <div className="headerimg">
                <img src={home} style={{ height: '155px', width: '100%' }} />
            </div>
            <div className="container" >
                <br></br>
                <div className="earlylife" >Central Food Technology Research Institute (CFTRI)</div>
                <br></br>
                <div className="earlylifepara" >In December 1943, the governing body of the CSIR set up an “Industrial Planning Committee with R.K. Shanmugha Shetty as Chairman. In its report in 1945, the committee recommended the setting of eleven specialized research Institutes in the areas of food technology, metallurgy, glass and silicate research, fuel research, oils and Paints, buildings and roads, industrial fermentation and electrochemicals beside the national physical and chemical Laboratories” (10). In 1944 Bires Chandra Guha (who had already been associated with the committee) joined the Ministry of Food, Government of India as Chief Technical Officer.</div>
                <br></br>
                <div className="earlylifeiyer">
                <div style={{ display: 'inline', float:'left', width:'25%', marginTop:'10px', marginLeft:'10px'}} >
                            <img className='reddyimg' src={bcguha} height={200} width={200} />
                            <div className='reddyimgdesc'>Bires Chandra Guha (1904-1962)</div>
                            <div className='reddyimgdesc'><a href="https://www.insaindia.res.in/BM/BM1_6613.pdf" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">Image Courtesy: INSA India</font></a></div>
                            <br></br>
                        </div>
                    <div className="earlylifepara" > Here Guha, initiated plans for carrying out nutrition surveys for the whole country. He also organized a technical wing for inspection, analysis and standardization of foods. “It was in the food department that he got the idea for Food Technological Research Institute and gave the idea some concrete shape” (8,12,14). The role of the Food Department in conceptualizing the Food Technology Institute is described in the report of first reviewing committee of the Council for Scientific and Industrial Research,1948 (2).  B.C. Guha had close contact with Subrahmanyan in a project on hydrogenated Fat as well   processing of soya milk and was aware that some of his current research at the Institute related to Food Technology both projects was funded by the C.S.I.R. I (2) Subrahmanyan was named as planning officer for the Food Institute (8,15). (The second reviewing committee of the Indian Institute of Science, 1948 (1) wrote “In 1936 the Department of Biochemistry was under the charge of Prof.V.Subrahmanyan. He was on stuydy leave in 1938 and Mr.Banerjee became Acting Head of Department. In  1940 the post of professor was readvertised. Prof.Subrahmanyan was reappointed and held the post till August 1948. He is engaged now on a government assignment but at his own option he may take up his assignment at the Institute again.”). Subsequently when Council of Scientific and Industrial Research (C.S.I.R.) decided to establish such an Institute in Mysore, Dr. S.S.Bhatnagar induced Guha to serve in the Executive council  for many sessions during its formative years (13). Apart from planning the work of the Institute, Subrahmanyan was given the task of searching for a place to house the Institute. The Governing Body of the Council sanctioned the necessary expenditure for formulating detailed plans for the Institute in February1948 (6). Subrahmanyan took over as Director of the Institute in 1950.</div>
                </div>
                <br></br>
                <div className="earlylifepara" >The Central Food Technological Research Institute is housed in lands enclosing the Cheluvamba Palace. The Cheluvamba Palace was a building constructed for the residence of Cheluvajamanni, who was the sister of the Maharaja H.H. Sri. Krishna Raja Wadiyar (born: 1884; Maharaja: 1894-1940). The building had been not been used for some years prior to its occupation by the C.F.T.R.I.</div>
                <br></br>
                <div className="earlylifeiyer">
                <div style={{ display: 'inline', float:'left', width:'25%', marginTop:'10px', marginLeft:'10px'}} >
                            <img className='reddyimg' src={kcreddy} height={250} width={200} />
                            <div className='reddyimgdesc'>K.C. Reddy (1902-1976) First Chief Minister of Erstwhile Mysore</div>
                            <br></br>
                        </div>
                    <div className="earlylifepara" > “The Government of Mysore had also generously offered a palatial building called “Cheluvamba Mansion” to the World Health Organization. As luck would have it within a few days the WHO announced their intention to locate their headquarters in Delhi” (9).  K.C. Reddy (1902 –1976), the Chief Minister of Mysore, was deeply interested that Food Technological Research Institute to be located in Mysore. Subrahmanyam, has said, that K.C. Reddy telegraphically requested Nehru to fix a date for his visit to Mysore, even before the formal decision of then Maharaja, Jayachamrajendra Wodeyar (Wadiyar) to hand over the building. The Cheluvamba Palace, when finally handed over, was at a rent of Rs. One per year. This rent was perhaps charged till the complete integration of Mysore state with the rest of India. The state was still under dyarchy at that time of takeover of the building by the Government of India (and interestingly the Dewan was Ramaswami Mudaliar who was instrumental in the foundation of the C.S.I.R.).</div>
                </div>
                <br></br>
                <div className="earlylifeiyer">
                <div style={{ display: 'inline', float:'left', width:'40%', marginTop:'10px', marginLeft:'10px'}} >
                            <img className='convocationimg' src={convocation} height={220} width={350} />
                            <div className='convocationimgdesc'>Convocation conferring the honorary Doctor of Science by the Chancellor Jayachamarajendra Wadiyar.</div>
                            <br></br>
                        </div>
                    <div className="earlylifepara" >Nehru visited Bangalore and Mysore in December 1948. He visited the Indian Institute of Science, drove to Mysore. He was awarded a Doctor of Science degree from the University of Mysore on 28th December 1948 and “In the afternoon of the 28th December, 1948 Pandit Nehru witnessed the famous Khedda operations at Kakankote.  A river drive was arranged and about thirteen wild elephants were captured on that day. Coming back to Mysore by dusk, Pandit Nehru visited the Krishnaraja Sagar Dam and the Brindavan Garden. At Hotel Krishnaraja Sagar he attended a banquet given in his honour and came back to Mysore late in the night. On the morning of the 29th, he received on behalf of the Government of India the building known as the "Cheluvamba Mansion," meant to house the Food Technological Institute of the Government of India.” (3). The third international forestry and timber utilization conference Asia and the Pacific convened by the FAO was held in the Cheluvamba Mansion Mysore in March 1949 (4).  In April 1949 the building was transferred to the Government of India.</div>
                    <div className="earlylifepara" >“Mysore is grateful to the Government of India for locating the All-India Food Technological Institute within her borders and it is with the greatest pleasure that she has place at the government of India’s disposal the spacious buildings and part of the grounds of the Cheluvamba Mansions for the housing of the Institute and its Laboratories. She is also glad that the proposal to add the Fruit Technology Section to the Institute is under active consideration” says K.C. Reddy in 1949. (5) </div>
                </div>
                <br></br>
                <div className="earlylifepara" >The arrangements for converting the palace into a laboratory commenced in April 1949 and before the end of the year, the Biochemistry and Nutrition Divisions and of the Processing Division were active before end of the year. In 1950 the Indian Institute of Fruit Technology was merged with the C.F.T.R.I and by July 1950 was actively carrying out research on the preservation and processing of fruits and vegetables and conducting the All-India Postgraduate Diploma Course in Fruit Technology (6).</div>
                <br></br>
                <div className="earlylifeiyer">
                <div style={{ display: 'inline', float:'left', width:'40%', marginTop:'10px', marginLeft:'10px', marginRight:'5px'}} >
                            <img src={cftriopening} height={250} width={380} />
                            <div className='inaugimgdesc' style={{fontSize:'12px', textAlign:'center'}}>Mr. C. Rajagopalchari (Minister without Portfolio) and Jayachamarajendra Wadiyar in the center. S.S. Bhatnagar to the left. K.C. Reddy to the right (not clear)</div>
                            <br></br>
                        </div>
                    <div className="earlylifepara" >The Central Food Technological Research Institute, Mysore was formally declared open by the Hon'ble Sri C. Rajagopalachari, on October 21, 1950. The Rajpramukh, the Maharaja of Mysore, presided.</div>
                    
                </div>
                <div className="earlylifepara" >C. Rajagopalachari in his opening remarks, acknowledged the work of Dr. Shanti Swarup Bhatnagar in speedily establishing national laboratories in the country. He drew attention to the vast scope for research in solving the food problems facing the country, and suggested that scientific research and technological processes should be employed to minimize all forms of food wastage and to preserve all surplus food material. Another field for investigation, he said, “is the elimination of the harmful constituents of non-edible materials, and the conversion of the latter in to healthy and attractive food. This also is a vast and very important field of investigation in the plan of supplementing our food resources. Closely linked up with this is the maintenance of nutritional standards by the use of staple subsidiary foods. There is again the problem of the manufacture of concentrated foods and accessories like vitamins on a large scale which would assist the maintenance of proper nutritional standards in India."</div>
                <br></br><br></br>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="backbutton" ><Button size="small" onClick={onClickBack} >Back</Button></div>
                    <div className="backbutton" ><Button size="small" onClick={onClickNext} >Next</Button></div>
                </div>
            </div>
        </>
    )
}