import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import home from '../../img/home.jpg';
import gilbertFowler from '../../img/gilbertFowler.jpg'

export default function GJFowler() {

    const history = useHistory();
    const onClickBack = (event) => {
        history.push("/acetone-fermentation");
    }

    return (
        <>
            <Helmet>
                <title>Dr. V. Subrahmanyan | G. J. Fowler</title>
            </Helmet>
            <div className="headerimg">
                <img src={home} style={{ height: '155px', width: '100%' }} />
            </div>
            <div className="container" >
                <br></br>
                <div className="earlylife" >Gilbert John Fowler</div>
                <br></br><br></br>
                <div>
                    <img style={{float:'left', marginRight:'15px', marginTop:'10px'}} src={gilbertFowler} height={300} width={300} />
                    <div className="earlylifepara">Gilbert John Fowler was born on January 23 1868 at Paris. From 1896 to 1916, he was responsible for sewage treatment at Manchester. He was awarded a D.Sc. from the University of Heidelberg in 1904. He developed the activated sludge process along with Arden and Lockett in 1914. He became professor of Applied Chemistry and the Department of Biochemistry in Indian Institute of Science at Bangalore in 1921. He had visited Rothamsted in 1921 and learned about the <a href="http://www.era.rothamsted.ac.uk/eradoc/OCR/ResReport1932-46-47" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">ADCO</font></a> process and became interested in composting. Between 1925 and 1953, Fowler was responsible for the installation of a number of sludge plants across India. A sewage plant was put up by Fowler in 1922 at IISc Bangalore proved valuable not only in elucidating the principle of the purification process but also demonstrating the value of sludge as an organic fertilizer. S.C. Pillai was the foremost student who continued with this work. Nigel Horan (2015) wrote a tribute “Celebrating the Revolution” to   a century after discover of the <a href=" https://aquaenviro.co.uk/wp-content/uploads/2015/04/Activated-sludge-process-article-Nigel-Horan.pdf" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9"> activated sludge process</font></a></div>
                </div>
                <br></br>
                <div className="earlylifepara" >Gilbert Fowler had obtained a patent for inventing an improved process for obtaining pure shellac from stick-lac (a stick with loads of eggs covered in lac) working with S. Mahdi Hassan and M. Sreenivasaya, deputed to the Institute by the Governments of Hyderabad and Mysore respectively (1).
                <br></br> <br></br>Dr. Gilbert Fowler retired from the Indian Institute of Science in 1924.</div>
                <br></br>
                <div className="earlylifepara" >In 1935, Ahmedabad city municipal sanitary committee chairman, Dr Hari Prasad Desai, had invited Dr Gilbert Fowler. Fowler then stated that Chinese farmers in Shanghai realized the value of organic manure. He said that conservancy cost the municipality there nothing at all and contractors were eager to get the night soil. The income thus realized was Rs.40,000 per annum. With a population of four lakh, there was a potential value of Rs.4 lakh a year in the waste of Ahmedabad. Fowler said, "Apart from sanitation, treating waste is a great business proposition,". He added, "India, like China, is an agricultural country and it needs organic manure. The proportion of organic matter in Indian soils is very low, about one-tenth of that in the soil of Europe and America. What I see in Ahmedabad is an economic sin. How can you pour organic manure into the river?". Fowler had told councilors that rich nitrate could be produced by adding organic wastes to the soil. "You can't grow things on mere sand", he said. He also suggested treating and utilizing effluents from industrial areas.</div>
                <br></br>
                <div className="earlylifepara" >After retirement from the Department of Biochemistry he was principal of the Hartcout Butler Technological Institute at Kanpur (Cawnpore). The book (2) that he published on nitrogen conservation was based on the lectures delivered by him at the Patna University as Sakhraj Ray reader in natural sciences.</div>
                <br></br>
                <div className="earlylifepara" >Subrahmanyan is mentioned in the book with reference to his work at Rothamsted on the chemistry of water-logged soils and at the Indian Institute of science   on the absorption of oxygen by the soil surface and his work with Jaghanath Rao on production of compost from towns’ refuse
                <br></br><br></br>In 1938 the address of Prof. Gilbert Fowler who was a member of the Indian National Science Academy was given as Consulting Chemist Mackay’s Garden annexe, Greames Road, Cathedral, P.O. Madras.</div>
                <br></br>
                <div className="earlylifepara" >He was married to Amy Hindmarsh (1874 in Rangoon) and had <a href="https://www.ancestry.co.uk/genealogy/records/gilbert-john-fowler-24-t0m00g" target="_blank" style={{ textDecoration: 'none', color:"#3819e9" }} >two children</a> Robert Scot Fowler (1901) and Gilbert Scot Fowler (1903). Both children were born at Manchester. He passed away on March 21, 1953 at Bangalore at the Central Hotel.</div>
                <br></br>
                <div className="earlylife" style={{ fontSize: 'large' }} >References</div>
                <br></br>
                <div className="referencepara" >(1)	Fowler, G.J., Sreenivasaya, A and Madhihassan, S. (1924) Contributions to the Scientific study of the Lac industry. Part 1-VIII, Journal of the Indian Institute of Science 7:97-144
                (2)	Fowler, G.J. (1934) <a href=" https://archive.org/details/introductiontoth030874mbp" target="_blank" style={{ textDecoration: 'none', color:"#3819e9" }} >An Introduction to the Biochemistry of nitrogen conservation</a>, Edward Arnold, London.
                (3)	Giri, K.V.; Rajagopalan R.; Pillai, S.C. (1953). Gilbert Fowler-Obituary" Current Science. 22 (4): 108.</div>
                <br></br><br></br>
                <div className="backbutton" ><Button size="small" onClick={onClickBack} >Back</Button></div>
            </div>
        </>
    )
}