import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory} from "react-router-dom";
import Button from '@mui/material/Button';
import home from '../../../img/home.jpg';
import macaroni from '../../../img/macaroni.png';
import macaroni1 from '../../../img/macaroni1.png';
import macaroni2 from '../../../img/macaroni2.png';
import macaroni3 from '../../../img/macaroni3.png';
import macaroni4 from '../../../img/macaroni4.png';
import macaroni5 from '../../../img/macaroni5.png';
import macaroni6 from '../../../img/macaroni6.png';
import './TapiocaMacaroni.css';
export default function TapiocaMacaroni() {
    const history = useHistory();
    const onClickNext = (event) => {
        history.push("/tapioca-macaroni-continued");
    }
    const onClickBack = (event) => {
        history.push("/sago");
    }

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <>
            <Helmet>
                <title>Dr. V. Subrahmanyan | Tapioca Macaroni</title>
            </Helmet>
            <div className="headerimg">
                <img src={home} style={{ height: '155px', width: '100%' }} />
            </div>
            <div className="container" >
                <br></br>
                <div className="earlylife" >Tapioca Macaroni</div>
                <br></br>
                <div className="earlylifepara" >During the years of the second world war scarcity of rice was felt all over India due to stopagge of  imports from Burma and due to both heavy rains in some parts and drought elsewhere.  (1).It would appear that rice had become dear (“This was the period when rice sold in the black market between Rs. 45 and Rs. 66 per bag of 162 lbs. in the Kottayam division of Travancore State as against a pre-war price of Rs. 8 and a controlled price of Rs. 80” (2) It is the availability of tapioca that prevented large scale deaths in Travancore at that time (24) It has also been noted that Tapioca grows in poor soils and yields 3-4 times the yield of grains. (5)</div>
                <br></br>
                <div className="earlylifepara">Subrahmanyan in 1950 publish a paper describing the process of making rice substitutes (3). Maize and ground nut cake which was cooked with water to a paste and  extruded manually through a dye. The strands were then dried. Round grains were made also from Tapioca and ground nut cake  (with and without added broken rice  wheat flour or sorghum flour) by shaking the mixture of the two flours on a sieve. The balls where then roasted in the presence of some saturaed fatty acid.  The cooking quality and appearance of the cooked product, made from different materials, was assesed as  possible rice substitues. The best were the ones made from cereal/tapioca and ground nut cake.  Weight gain was much  higher in  rats fed  Tapioca/Ground Nut/broken rice or Tapioca/Ground Nut/ Wheat than when fed raw milled rice.</div>
                <br></br>
                <div className="earlylifepara">Subrahmanyan (6) reasoned out that, Tapioca was chosen because it is capable of growing in conditions when the cereal crops failed. The protein content of the root was very low. “The researches conducted in the Central Food Technological Research Institute, Mysore, since I949 have shown that Tapioca flour can be suitably enriched and fortified with specially prepared oil-seed meals.” The mixture was tested in rats (supplemented with vitamins and minerals) and found to be adequate round grains were made from the mixture which were demonstrated in 1950 in Trivandrum “A suggestion was made that a product with the shape of rice would be preferred by the consumer. (7) Moreover, it was felt that rice was unique in many ways. It is neutral in flavor, could be used in various forms and is easily cooked and digested. Rice has certain unique properties which are not shared by other food grains. Grains such as wheat, jowar and maize did not cook to a similar softness as did rice. Hence rice was taken as the model for making the “synthetic rice”. (3,5,10)</div>
                <br></br>
                <div className="earlylifepara">A committee appointed by   the Ministry of Natural Resources and Scientific Research after much deliberation approved Rs 5.4 Lakhs for the project and purchase of equipment. Subrahmanyan undertook a tour of Europe in 1953. Messers Buhler Brothes, Uzwil Switzerland received the order for the machinery.  Dr.D.S. Bhatia visited with them and along with the Swiss food technologist Dr. Ing Hummel studied the problem and after various manufacturing trials, the design specifications design of the plant were finalized.  The plant was received in Mysore in June 1956. (6) “Further trials and standardization of working conditions took some more months and the plant was ready for operation only in March 1957. Buhler brothers, Uzwill, Switzerland is displaying a scale-model of an industrial tapioca macaroni plant at the world agricultural fair here”. (12) </div>
                <br></br>
                <div className="earlylifepara">The first world agriculture fair (WAF) was held on 11th Dec.1959 -11 March 1960 at New Delhi. Dr. Panjabrao Deshmukh Union Minister of India and President of Bharat Krushak Samaj organized the <a href="https://sites.google.com/view/worldagricultureexhibition/introduction" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9">world agriculture fair</font></a> and Government of India assisted the fair.</div>
                <br></br>
                <div className="earlylifepara">The pilot plants set up in Mysore (9) by Buhler (Larsen and Toubro?) consisted of</div>
                <div className="earlylifepara">1.	Hammer Mill fitted with feed hopper, feeding device and device for sieving the ground flour. The mill could process 1000 lb./hr. of tapioca flour and 500 lb./hr. of groundnut flour and was dust free</div>
                <div className="earlylifepara">2.	A batch type mixer processing 1600lb in 2hr. The dough was made by mixing the dough in hot water followed by kneading.</div>
                <div className="earlylifepara">3.	The Extrusion process consisted of a device feeding the flour to a mixer which was dosed with hot and cold water. The dough was and extruded through selected dyes with an: extrusion cylinder” and cut into desired length. The extrusion cylinder was cooled with circulating water. The press could extrude 259 lbs per hour</div>
                <div className="earlylifepara">4.	Different dyes were available so that differently shaped products could be made  </div>
                <div className="earlylifepara">5.	Speed of the knife could be varied to obtain differently sized products</div>
                <div className="earlylifepara">6.	The cut pieces were then moved to a pre drier fitted “with oscillating sieves driven by an eccentric shaft”. Hot air was passed from below.</div>
                <br></br>
                <div className='earlylifepara'>The semolina would now move to a drier cum roaster. The drier consisted of rotating drums (There were a total of twelve drums). The twelve cylinders were divided into three zones/compartments. The middle compartment was not heated. The first zone was kept at a higher temperature than the third. The drying temperatures in the first, second and third zones are 60°C, 50°C and 55°C. respectively. After drying, the macaroni was introduced into the driers for roasting where the first zone was maintained at 115°C-120°C in Zone I and the second zone at 85°C-90°C. This roasting step reduced leaching loss from the product on cooking.</div>
                <br></br>
                <div className='earlylifepara'>Alternately a steaming step was used on the extruded pre-dried dough. “The steamed product had longer shelf life, is harder grain and has better eating quality than the unsteamed product.” (5). The product was stored in cloth bags and later transferred to polyethylene bags.</div>
                <br></br>
                <div className="earlylifeiyer">
                    <div className='macaroni' style={{ display: 'inline', float:'left', width:'30%', marginRight:'30px', marginTop:'10px' }} >
                            <img  src={macaroni} height={325} width={285}/>
                            <p className='macaronidesc'>The hammer mill for grinding tapioca chips and ground nut meal. Dr. Hummel, visiting Swiss technologist and Dr.D.S. Bhatia are seen in the picture (taken from “Tapioca goes to the People”). Dr. Charles Humel, the visiting Swiss Macaroni Expert is thanked in a paper “for guidance and assistance during the initial stages of the running of the plant” (7,8)</p>
                    </div>
                    <div className="earlylifepara">Tapioca flour was prepared from cleaned, white tapioca whose outer skin and inner rind had been removed. Cleaned ground nut seeds were in a coffee roaster, cuticle removed, pressed in an oil expeller and ground to flour. It was desirable that the press cake (oil about 5-6%) be used within a week of grinding. Wheat semolina (suji) was procured commercially and ground. The three ingredients were mixed in the proportion of 60:15:25. Boiling water was added to the flour and mixed for about 12 minutes. The dough was then extruded, cut into shape and pre-dried (60°C to 50°C) initially to a moisture of about 30%. The moisture was brought down to about 17% to 10%. 
                    The dried material was then again taken into the three-stage drier and exposed to a temperature of 115{'\u00b0'}C to 120{'\u00b0'}C in the first drier and 85{'\u00b0'}C to 90{'\u00b0'}C in the third drier. The moisture content of the product was brought down to 6.5%. It was discovered that steaming the product was beneficial in improving both shelf life and cooking quality of the product (A firm product losing less solids into cook water) The pre-dried product was then steamed, dried and the moisture content brought down to 10%. The macaroni was placed in polyethylene bags. (8) </div>
                </div>
                <br></br>
                <div className='earlylifepara'>Use of saturated fatty acid and coating with calcium caseinate to help make a firmer product after cooking has been discussed (5). A blend of 70 parts of wheat flour, 25 parts of tapioca flour and 5 parts of ground nut flour could be used to make chapatis and pooris. (9).</div>
                <br></br>
                <img className="infantFood1" src={macaroni1} style={{height:'650px'}}/>
                <br></br>
                <div className='earlylifepara'>There was a meeting of the Sarvodaya movement at Yelwal near Mysore in September 1957. Jawaharlal Nehru, G.B. Pant. <a href="https://ia601005.us.archive.org/30/items/acharyavinobabha00tand/acharyavinobabha00tand.pdf" target="_blank" style={{ textDecoration: 'none' }} ><font color="#3819e9"> Acharya Vinjoba Bhave</font></a>, Jai Prakash Narayan, E.M.S. Namboodiripad, Rajendra Prasad, Sri U.N. Dhebar, Sri.S.K. Dey, Dr. Jivraj Mehta, Sri. V.T. Krishnamachari, Sri.S.V. Ramamurthy and many others who attended the meeting. visited the C.F.T.R.I.  Nehru felt that the plant in Kerala be fully automatic. G.B. Pant appealed to all states to try out the macaroni (7)</div>
                <br></br>
                <div className="earlylifediv" >
                        <div className="macaroniimg"  >
                            <img src={macaroni2} height={200} width={200} />
                            <br></br>
                        </div>
                        <div className="macaroniimg1" >
                            <img src={macaroni3} height={200} width={300} />
                            <br></br>
                        </div>
                        <div className="macaroniimg1"  >
                            <img src={macaroni4} height={200} width={300} />
                        </div>
                </div>
                <div className="earlylifediv" >
                        <div className="macaroniimg" >
                            <img src={macaroni5} height={200} width={250} />
                        </div>
                        <br></br>
                        <div className="macaroniimg1" >
                            <img src={macaroni6} height={200} width={250} />
                        </div>
                        <br></br>
                        <div className="earlylifedesc" style={{ marginLeft: '20px', marginRight:'30px' }} >K.C. George, Nehru, Vinobabhave, Jayaprasknaryan and G.B. Pant. The photo of K.C. George  is taken from “Tapioca Goes to the People” (7) </div>
                </div>


                <br></br>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="backbutton" ><Button size="small" onClick={onClickBack} >Back</Button></div>
                    <div className="backbutton" ><Button size="small" onClick={onClickNext} >Next</Button></div>
                </div>
            </div>
        </>
    )
}