import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

export default function Footer() {
   
    return (
        <>
            <div className="footer" >
                <div className="footerdiv">
                <table>
                        <tbody>
                            <tr>
                                <td className="tablecolumn" >
                                    <div className="footerpara" >
                                     <font size="3" color="#ffffff">
                                          <span><Link to="/site-map"><font fontWeight="600" color="#e9cf76" >INFO</font></Link></span>
                                          <br></br>
                                          <div className='footertext'><a href="https://www.facebook.com/SubrahmanyanV/" target="_blank" style={{ textDecoration: 'none' }} ><font color="#e9cf76"> Facebook |</font></a> <a href="https://www.youtube.com/channel/UCLXXc7YtMIIlWd5sT4jia7A" target="_blank" style={{ textDecoration: 'none' }} ><font color="#e9cf76"> Youtube</font></a></div>
                                     </font>
                                </div>
                            </td>
                                <td className="anothertablecolumn" >
                                    <div className="anotherfooterpara" >
                                      <font color="#fff3f3" size="3">
                                           <span><font fontWeight="600" color="#e9cf76" >CONTACT US</font></span>
                                           <br></br>
                                            <div className="footertext"><a href="https://mail.google.com/mail/u/1/?view=cm&fs=1&to=dr.v.subrahmanyan@gmail.com&tf=1" target="_blank" style={{ textDecoration: 'none' }} ><font color="#e9cf76"> E-Mail </font></a></div>
                                       </font>
                                 </div>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </>
    )
}