import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import home from '../img/home.jpg';
import award from '../img/award.png';
import award1 from '../img/award1.png';
import award2 from '../img/award2.png';
import award3 from '../img/award3.png';
import award4 from '../img/award4.png';
import './Awards.css';

export default function Awards() {
    const history = useHistory();
    const onClickHome = (event) => {
        history.push("/");
    }
    const onClickBack = (event) => {
        history.push("/post-harvest-tech");
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Dr. V. Subrahmanyan | Awards</title>
            </Helmet>
            <div className="headerimg">
                <img src={home} style={{ height: '155px', width: '100%' }} />
            </div>
            <div className="container" >
                <br></br>
                <div className="earlylife" >Awards</div>
                    <br></br>
                    <div className="earlylifeiyer">
                        <div style={{display: 'inline', float:'right', width:'30%', marginLeft:'20px', marginTop:'10px'}}>
                            <img className="awards" src={award} height={350} width={300} />
                            <div className="imgdesc" style={{fontSize:'12px', textAlign:'center'}}>Subrahmanyan receiving the Padma Shri medal from Dr. Rajendra Prasad, the then president of India</div>
                            <br></br>
                        </div>
                    <div className="earlylifepara" >Subrahmanyan received the Sen medal from the Institution of Chemists (1959) , the Rafi Ahmed Kidwai Award for work on the development of baby food and the Padma Shri in 1960. In 1962 he received the Babcock Hart Award from the Institute of Food Technologists USA for work on the development of the Indian Multi-Purpose Food.</div>
                    <br></br>
                    <div className="earlylifepara" >The first "Friesland" Awarded to Dr. V. Subrahmanyan by Cooperative Milk Products Factory On September 10, 1963, on the occasion of their 50th anniversary of the Cooperative Condensfabriek in the Netherlands.</div>
                    <br></br>
                    <div className="earlylifepara" >In 1963 he received the Raffi Ahmed Kidwai prize once more for development of techniques, for wax coating of fruits and vegetables to increase their shelf life.  He gave the Bires Chandra Guha Memorial Lecture in 1969 at the INSA. He received the K.G. Naik medal for distinguished contributions to biochemistry in 1964 from the University of Baroda. In 1966, Subrahmanyan received an award from the National Institute of Science and Technology, Philippines.</div>
                </div>
                
                <div className="earlylifediv">
                        <div className="awardsimg">
                            <img src={award3} height={250} width={300} />
                        </div> 
                        <div style={{ display: 'inline', marginLeft: '50px' }} >
                            <img src={award4} height={250} width={300} />
                        </div>
                </div>
                <br></br><br></br>
                <div className="earlylifepara" >The Plaque reads:</div>
                <div className="earlylifepara" >“In recognition of his outstanding contribution in coconut technology while consultant to the Institute thereby opening up new and unlimited possibilities of the coconut, both as an article of trade and commerce and as a food commodity.</div>
                <div className="earlylifepara" >In appreciation of his dedication, zealousness and devotion to his work and thereby exemplifying among his colleagues the true spirit of a researcher</div>
                <div className="earlylifepara" >For his understanding and generosity which has generated cooperation among his co-workers and endeared him as well to his friends and associates: and</div>
                <div className="earlylifepara" >For his avowed primary object to ameliorate the standard of living of the common man through advancement in food technology and industry</div>
                <div className="earlylifepara" >Given on July 18 in the year of our Lord 1966 at the City of Manilla, Philippines.</div>
                <div className="earlylifepara" >CAUTO G MANUEL</div>
                <div className="earlylifepara" >Commissioner”</div>
                <br></br>
                <div className="earlylifepara" >On 28th July 1966 at Cotabato City in the Philippines he received a citation for "his unselfish and dedicated efforts in the upliftment of the welfare of the coconut farmers of Cotabato province by his research and the final farm application of the treatment of coconut kernels with chemicals to produce quality copra" from the Moru Gulf Coconut Planters Association.</div>
                <br></br>
                <div className="earlylifepara" >The Annual Report of the National Institute of Sciences of India (Indian National Science Academy) for the year 1938 mentions that Prof. M. N. Saha was president and Prof. S. S. Bhatnagar was vice president.   Prof. V. Subrahmanyan was cited as professor of Biochemistry, foundation fellow and council member. He also was in the chemistry committee. He was a committee member of the INSA in 1952-1954 and 1959-1960 as well .</div>
                <br></br>
                <div className="earlylifepara" >The Indian Academy of Sciences was registered under the Society Registration Act, 1860 on 27 April 1934 at Bangalore, which was then administered with a Governing Council consisting of Sir C.V. Raman, Prof. C.R. Narayana Rao, Dr. Subba Rao and Prof. B. Venkatesachar as members and Profs. L. Rama Rao, V. Subrahmanyan and B.K. Narayana Rao as council members. The Academy was inaugurated by Sir Mirza M. Ismail, the Dewan of Mysore 31 July 1934. V. Subrahmanyan was elected Animal Science Section into this Society in 1934 and was treasurer from 1934-1937.</div>
                <br></br>
                <div className="earlylifepara" >The Society of Biological Chemists (India)" was founded in 1930, with its Head Quarters at Indian Institute of Science, Bangalore. It was registered under the Societies Act in the then Princely State of Mysore and the memorandum of registration was signed by the Profs V Subramanian, V Patwardhan and C V Natarajan, Dr. G. J. Fowler was president in 1931 and from 1946-1948. V. Subrahmanyan was president in 1950-1951.</div>
                <br></br>
                <div className="earlylifepara" >The Association of Food Technologists (AFT) was founded in 1957 in the Campus of the C.F.T.R.I. It was renamed in 1968 as the Association of Food Scientists and Technologists (AFST). Dr. Subrahmanyan was president of the association from 1957 to1963.  The V. Subrahmanyan Industrial Achievement award was instituted by the Association of Food Science and Technologists India. Awardees of the V. Subrahmanyan Industrial Achievement award include Dr. H. S. R. Desikachar (1975), Dr. G.S. Siddapa (1977), Mr. C. P. Natarajan (1981), and Mr. M.R. Chandrashekara (2000) who were at one time associates of V. Subrahmanyan.</div>
                <div className="earlylifediv">
                        <div className="awardsimg">
                            <img src={award1} height={200} width={300} />
                        </div> 
                        <div className="awardsimg1">
                            <img src={award2} height={200} width={300} />
                        </div>
                    </div>
                <br></br>
                <div className="earlylifepara" >On Feb 13, 1979 Subrahmanyan and others who had been at the C.F.T.R.I. from its inception (Twenty-Five Years’ Service Medal) received a medal of honor from Smt. Indira Gandhi, the then Prime minister of India.</div>
                <br></br>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="backbutton" ><Button size="small" onClick={onClickBack} >Back</Button></div>
                <div className="backbutton" ><Button size="small" onClick={onClickHome} >Back to Home</Button></div>
                </div>
            </div>
        </>
    )
}